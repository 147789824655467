import {
  ArrowDownIcon,
  ArrowUpIcon,
  QuestionOutlineIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import { Box, Button, Center, Flex, IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import {
  IAppState,
  moveSentence,
  toggleQuestionDisplay,
} from '../data/appState';
import QuestionToast from './QuestionToast';
import { scrollbarCSS } from '../style/scrollbarCSS';

const SentencesDisplay: React.FC<{
  appState: IAppState;
  update: (appState: IAppState) => void;
}> = ({ appState, update }) => {
  const previousSentence = useCallback(async () => {
    update(await moveSentence(appState, -1));
  }, [appState, update]);

  const nextSentence = useCallback(async () => {
    update(await moveSentence(appState, 1));
  }, [appState, update]);

  const toggleQuestionAndContext = async () => {
    update(await toggleQuestionDisplay(appState));
  };

  const sentence = appState.sentences[appState.currentSentence].label;
  const question = appState.sentences[appState.currentSentence].question;
  const context = appState.sentences[appState.currentSentence].context;

  const { label } = appState.sentences[appState.currentSentence];
  let width = 500;
  if (label.length > 200) width = 600;
  if (label.length > 300) width = 700;
  if (label.length > 400) width = 800;

  (document.activeElement as HTMLElement).blur();

  return (
    <>
      <QuestionToast.Wrapper
        appState={appState}
        close={toggleQuestionAndContext}
      />
      <Flex justifyContent="center" alignItems="center" height={150}>
        <Box width="70vw" minHeight="100px" padding={2} mt="5rem">
          <Center width="100%" minHeight={90}>
            <Box
              width={width}
              color="primary.500"
              fontWeight="600"
              fontSize={22}
              overflowY={sentence.length > 300 ? 'scroll' : 'hidden'}
              height={sentence.length > 300 ? '10rem' : 'auto'}
              css={scrollbarCSS}
            >
              {sentence}
            </Box>
          </Center>
        </Box>
        <Flex alignItems={'center'} position="absolute" top="30%" right="5%">
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            ml={2}
            height="80%"
          >
            {(question || context) && (
              <IconButton
                aria-label="show-question"
                colorScheme={'primary'}
                position="absolute"
                top="-20"
                icon={
                  appState.showQuestion ? (
                    <CloseIcon w={6} h={4} />
                  ) : (
                    <QuestionOutlineIcon w={6} h={6} />
                  )
                }
                onClick={() => {
                  toggleQuestionAndContext();
                }}
              />
            )}
            <Button
              onClick={previousSentence}
              disabled={appState.currentSentence === 0}
              pl={1}
              pr={1}
              mb={5}
            >
              <ArrowUpIcon w={6} h={6} />
            </Button>
            <Button
              onClick={nextSentence}
              disabled={
                appState.currentSentence === appState.sentences.length - 1
              }
              pl={1}
              pr={1}
              mt={5}
            >
              <ArrowDownIcon w={6} h={6} />
            </Button>
          </Flex>
          <Box
            ml={5}
            color="primary.500"
            width={100}
            fontWeight={600}
            fontSize={24}
          >
            {appState.currentSentence + 1} / {appState.sentences.length}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default SentencesDisplay;
