import { AnswerValues } from './data/appState';

export const BASE_URL = process.env.REACT_APP_CHURCHILL_BACK_API_BASE_URL;
export const GET_SLOT_ENDPOINT = '/matrices/requestSlot';
export const SEND_SLOT_ENDPOINT = '/matrices/slots/{slotId}/results';
export const CHECK_SLOT_ENDPOINT = '/matrices/inProgressSlot';
export const RESET_SLOT_ENDPOINT = '/matrices/resetSlot';

export const UI_MIN_DIM_PX_WIDTH = 130;
export const ACTIVITY_TIMER = 30000;

export const COLOR_VALUES: Record<AnswerValues, string> = {
  [-1]: '#E4E4EA',
  0: '#580073',
  1: '#00D79E',
  2: '#2041CE',
  3: '#E70D67',
};
