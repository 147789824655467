import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const QuitModal: React.FC<{
  isOpen: boolean;
  validate: () => void;
  close: () => void;
}> = ({ isOpen, validate, close }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('quitModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          dangerouslySetInnerHTML={{ __html: t('quitModal.message') }}
        />
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={close}>
            {t('quitModal.button.quit')}
          </Button>
          <Button mr={3} onClick={validate} colorScheme="accent">
            {t(`quitModal.button.ok`)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuitModal;
