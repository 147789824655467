import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InvalidChunk: React.FC<{
  isOpen: boolean;
  validate: () => void;
}> = ({ isOpen, validate }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('invalidChunk.title')}</ModalHeader>
        <ModalBody
          dangerouslySetInnerHTML={{ __html: t('invalidChunk.message') }}
        />

        <ModalFooter>
          <Button colorScheme="accent" mr={3} onClick={validate}>
            {t('invalidChunk.ok')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InvalidChunk;
