export default {
  APP_NAME: 'Connexion',
  send: 'Envoyer',
  sendResults: 'Envoyer les résultats',
  quit: 'Quitter',
  key: "Clé d'identification",

  start: 'Valider',

  values: {
    shortLabel: {
      '-1': ' ',

      '0': ' ',

      '1': 'En accord',

      '2': 'Neutre',

      '3': 'Désaccord',
    },

    longLabel: {
      '0': 'Dimension non présente dans le texte',

      '1': 'Texte en accord avec la dimension',

      '2': 'Dimension évoquée de façon neutre',

      '3': 'Texte en désaccord avec la dimension',
    },
    shortcuts: {
      '0': '(espace)',

      '1': '(i)',

      '2': '(o)',

      '3': '(p)',
    },
  },

  header: {
    analysis: 'Analyse',

    ID: "Clé d'identification",

    expires: "Analyse jusqu'au",

    count: "Nombre d'analyse·s réalisée·s",
  },

  keys: {
    name: {
      tab: 'tab',

      shift: 'maj',

      space: 'espace',

      i: 'i',

      o: 'o',

      p: 'p',

      d: 'd',

      c: 'c',

      y: 'y',
    },

    desc: {
      tab: 'Dimension suivante',

      'shift+tab': 'Dimension précédente',

      space: 0,

      i: 1,

      o: 2,

      p: 3,

      d: 'Texte précédent',

      c: 'Texte suivant',

      y: 'Détail à propos du texte',
    },
  },

  instructions: {
    title: 'Informations',

    messageHTML: `

<h2>Contexte</h2>

<p>Les équipes de Humans Matter privilégient des modes de consultation pour recueillir la parole,

identifier les freins comportementaux notamment aussi bien que les leviers d'action.

Pour l'analyse fine des textes recueillis, nous avons besoin d'élargir l'équipe. Bienvenue !</p>

<h2>Principe</h2>

<ul>

  <li>Cette analyse consiste à qualifier des textes courts en regard de plusieurs dimensions.</li>

  <li>Pour chaque texte, il s'agit d'indiquer si chacune des dimensions est présente ou non, et de quelle manière.</li>

  <li>Nous vous conseillons d'apprendre rapidement les raccourcis clavier qui permettent d'être efficace pour faire les choix et naviguer.</li>

</ul>

<h2>Durée</h2>

<ul>

  <li>La durée d'analyse dépend du nombre de textes, de dimensions et de votre vitesse de traitement. À titre indicatif 20 à 30 min pour 100 lignes x 10 dimensions.</li>

  <li>Le temps effectif est enregistré et transmis avec les réponses pour une réévaluation a posteriori.</li>

</ul>

<h2>Qualité</h2>

<ul>

  <li>Vous pouvez participer à environ 4h d'analyses par jour. Au-delà, la cohérence de vos analyses pourrait être affectée. </li>

  <li>Cette cohérence est vérifiée et indiquée au moment d'envoyer chaque analyse.</li>

  <li>Ménagez-vous des pauses régulières.</li>

</ul>

<h2>Expiration</h2>
<ul>

  <li>L'analyse en cours est disponible jusqu'à l'heure indiquée au sommet de la page. Si elle n'est pas terminée à temps, les réponses sont perdues et l'analyse est considérée comme non réalisée.</li>

</ul>

`,

    ok: 'Valider',
  },

  // Si, au rechargement, l'analyse n'est pas valide (le serveur n'a pas cette analyse pour la clé donnée)

  invalidChunk: {
    title: 'Clé invalide',

    message:
      "La clé saisie ne permet pas d'accéder à une analyse.<br>Vérifiez votre saisie ou rapprochez-vous de votre correspondant <a href='mailto:humanpower@humansmatter.co'>humanpower@humansmatter.co</a>.",

    ok: "Retour à l'accueil",
  },

  // Quand temps analyse a expiré

  timeExpired: {
    title: 'Analyse expirée',

    message:
      'Le temps pour cette analyse a été dépassé.<br>Cette analyse a été réinitialisée pour être redistribuée.',

    ok: "Retour à l'accueil",
  },

  // Si, au rechargement, on ne peut pas vérifier l'analyse (cas le plus probable : pas de réseau)

  noNetwork: {
    title: 'Identification non confirmée',

    message:
      "La connexion n'a pas pu être établie.<br>Vérifiez votre réseau internet ou rapprochez-vous de votre correspondant <a href='mailto:humanpower@humansmatter.co'>humanpower@humansmatter.co</a>.",

    ok: 'Réessayer',
  },

  // Modal d'envoi des résultats, avec 2 = avant envoi et 3 = après envoi

  sendModal: {
    title: 'Envoi des résultats',

    message: {
      2: 'Score de cohérence: {{coef}}',

      3: 'Les résultats ont bien été envoyés',
    },

    button: {
      send: 'Envoyer',

      quit: "Retour à l'analyse",

      new: 'Continuer',

      home: "Retour à l'accueil",
    },
  },

  quitModal: {
    title: "Quitter l'analyse",
    message: 'En quittant, toutes les réponses données seront perdues.',
    button: {
      quit: 'Annuler',
      ok: 'Confirmer',
    },
  },

  // Validation de la clé sur la page de connexion

  getChunk: {
    error: {
      unknown: 'Clé inconnue',

      finished:
        'Toutes les analyses disponibles avec cette clé ont été terminées. Merci !',
    },
  },

  // Si à l'envoi de l'analyse le serveur dit qu'elle n'est pas valide

  sendResponse: {
    unauthorized:
      "L'analyse n'a pas pu être transmise.<br>Rapprochez-vous rapidement de votre correspondant <a href='mailto:humanpower@humansmatter.co'>humanpower@humansmatter.co</a>.",
  },

  // Ca devrait pas arriver ... mais on sait jamais

  unknownError:
    "Une erreur est survenue.<br>Rapprochez-vous rapidement de votre correspondant <a href='mailto:humanpower@humansmatter.co'>humanpower@humansmatter.co</a>.",

  recommendations: {
    screenSizeError:
      'La plateforme Humans Matter Assessments est utilisable uniquement sur un ordinateur de bureau. Si vous utilisez un smartphone, veuillez changer de terminal pour accéder à la plateforme.',
    windowSizeError:
      "L'écran que vous utilisez semble trop petit. Veuillez agrandir la fenêtre de votre navigateur au maximum, et vérifier que le zoom n'est pas activé.",
    recommendations:
      "La taille d'écran minimale requise est {{recoWidth}}px (largeur) et {{recoHeight}}px (hauteur).<br />La taille actuelle de votre page est {{actualWidth}}px * {{actualHeight}}px.",
  },

  // Pour l'affichage de la question et de la réponse entière (si celle-ci a été tronquée),
  // => textes fournit par l'API, ici juste des fallbacks

  defaultQuestionAndContext: {
    question: 'Question posée',
    context: 'Texte entier',
  },

  fatalError: {
    title: 'Erreur',
    text: "Votre clé ou l'application est ancienne, veuillez recharger la page SVP",
  },
};
