import { InfoIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem } from '@chakra-ui/react';
import { padStart } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAppState,
  reset,
  resetSlot,
  updateInstructionsSeen,
  updateShowSendModal,
} from '../data/appState';
import QuitModal from '../modals/QuitModal';

const Header: React.FC<{
  appState: IAppState;
  update: (appState: IAppState) => void;
}> = ({ appState, update }) => {
  const { t } = useTranslation();
  const [showQuit, setShowQuit] = useState(false);
  const showSendModal = async (show: boolean) => {
    update(await updateShowSendModal(appState, show));
  };
  const showInstructionsModal = async () => {
    update(await updateInstructionsSeen(appState, false));
  };

  const expirationDate = useMemo(() => {
    const d = new Date(appState.expirationDate);
    const dd = padStart(`${d.getDate()}`, 2, '0');
    const mt = padStart(`${d.getMonth() + 1}`, 2, '0');
    const hh = padStart(`${d.getHours()}`, 2, '0');
    const mm = padStart(`${d.getMinutes()}`, 2, '0');
    return `${dd}/${mt}/${d.getFullYear()} ${hh}h${mm}`;
  }, [appState.expirationDate]);

  const quit = useCallback(async () => {
    await resetSlot(appState);
    update(await reset(undefined));
  }, [update]);

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      background="white"
      pb={3}
      pl={4}
      pr={10}
      pt={2}
      boxShadow="0 3px 24px rgba(0, 0, 0, 0.16)"
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Button colorScheme="transparent" onClick={showInstructionsModal}>
            <InfoIcon color="primary.500" w={6} h={6} />
          </Button>
          <Flex
            alignItems="start"
            direction="column"
            color="primary.500"
            ml={2}
          >
            <Box>
              <b>{t('header.ID')} :</b> {appState.key}
            </Box>
            <Box>
              <b>{t('header.expires')} :</b> {expirationDate}{' '}
            </Box>
            <Box>
              <b>{t('header.count')} :</b> {appState.nbSlotsAnnotated}
            </Box>
          </Flex>
        </Flex>
      </Box>
      <GridItem>
        <Flex justifyContent="center" alignItems="center" flexDir="column">
          <Button
            onClick={() => showSendModal(true)}
            disabled={!appState.reachedEnd}
            backgroundColor="accent.500"
            mb="2"
            mt="2"
          >
            {t('sendResults')}
          </Button>
          <a
            style={{
              textDecoration: 'underline',
              fontSize: 12,
              marginLeft: 8,
              cursor: 'pointer',
            }}
            onClick={() => setShowQuit(true)}
          >
            [{t('quit')}]
          </a>
        </Flex>
      </GridItem>
      <QuitModal
        isOpen={showQuit}
        close={() => setShowQuit(false)}
        validate={() => quit()}
      />
    </Flex>
  );
};

export default Header;
