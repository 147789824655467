import { cloneDeep } from 'lodash';
import i18n from 'i18next';
import {
  BASE_URL,
  CHECK_SLOT_ENDPOINT,
  GET_SLOT_ENDPOINT,
  RESET_SLOT_ENDPOINT,
  SEND_SLOT_ENDPOINT,
} from '../config';

export enum SendStatus {
  NOT_SENT = 0,
  WANTS_TO_SEND = 1,
  CAN_SEND = 2,
  SENT = 3,
}

export const AnswerEnum: { [n in string]: number } = {
  NOTHING: -1,
  NOT_PRESENT: 0,
  RIGHT_DIMENSION: 1,
  NEUTRAL_DIMENSION: 2,
  WRONG_DIMENSION: 3,
} as const;

// Get 0, 1, 2... as type
export type AnswerValues = typeof AnswerEnum[keyof typeof AnswerEnum];

type DimensionsConfig = {
  [n in AnswerValues]: {
    longLabel: string;
    shortLabel: string;
    output: number;
  };
};

export interface IAppState {
  key: string;
  matrice: AnswerValues[][];
  currentSentence: number;
  currentDimension: number;
  lastChange: number;
  expirationDate: string;
  coef?: number;
  coefMessage?: string;
  seenInstructions: boolean;
  sending: SendStatus;
  slotId: string;
  sentences: {
    id: string;
    label: string;
    question?: string;
    context?: string;
  }[];
  dimensions: { id: string; label: string; info: string }[];
  dimensionsConfig: DimensionsConfig;
  duration: number;
  reachedEnd: boolean;
  startDate: number;
  nbSlotsAnnotated: number;
  showQuestion: boolean;
  toastTitles: { questionTitle: string; contextTitle: string };
}

type AppResponse =
  | { isError: true; message: string }
  | { isError: false; state: IAppState };

const err = (txt: string): { isError: true; message: string } => ({
  isError: true,
  message: txt,
});

//
// API
//

interface GetChunkResponseOnSuccess {
  keyId: string;
  slotId: string;
  nbSlotsAnnotated: number;
  expirationDate: string;
  data: {
    dimensions: { id: string; label: string; info: string }[];
    dimensionsConfig?: DimensionsConfig;
    sentences: {
      id: string;
      label: string;
      question?: string;
      context?: string;
    }[];
    toastTitles?: { questionTitle: string; contextTitle: string };
  };
}

export async function getChunk(key: string): Promise<AppResponse> {
  const resp = await fetch(`${BASE_URL + GET_SLOT_ENDPOINT}?key=${key}`);

  if (resp.ok) {
    const chunk: GetChunkResponseOnSuccess = await resp.json();
    const row = chunk.data.dimensions.map(() => -1);
    const matrice = chunk.data.sentences.map(() => [...row]);
    const toastTitles = chunk.data.toastTitles ?? {
      questionTitle: i18n.t('defaultQuestionAndContext.question'),
      contextTitle: i18n.t('defaultQuestionAndContext.context'),
    };
    const dimensionsConfig = chunk.data.dimensionsConfig ?? {
      0: {
        longLabel: 'Dimension non présente dans le texte',
        shortLabel: '',
        output: 0,
      },
      1: {
        longLabel: 'Texte en accord avec la dimension',
        shortLabel: 'En accord',
        output: 1,
      },
      2: {
        longLabel: 'Dimension évoquée de façon neutre',
        shortLabel: 'Neutre',
        output: 2,
      },
      3: {
        longLabel: 'Texte en désaccord avec la dimension',
        shortLabel: 'Désaccord',
        output: 3,
      },
    };

    return {
      isError: false,
      state: {
        slotId: chunk.slotId,
        sentences: chunk.data.sentences,
        dimensions: chunk.data.dimensions,
        dimensionsConfig: {
          [-1]: {
            shortLabel: '',
            longLabel: '',
            output: -1,
          },
          ...dimensionsConfig,
        },
        key,
        matrice,
        currentSentence: 0,
        lastChange: Date.now(),
        expirationDate: chunk.expirationDate,
        seenInstructions: false,
        sending: SendStatus.NOT_SENT,
        duration: 0,
        currentDimension: 0,
        reachedEnd: false,
        startDate: Date.now(),
        nbSlotsAnnotated: chunk.nbSlotsAnnotated,
        showQuestion: false,
        toastTitles,
      },
    };
  }
  if (resp.status === 400 || resp.status === 401) {
    return err('getChunk.error.unknown');
  }
  if (resp.status === 404) {
    return err('getChunk.error.finished');
  }
  return err('unknownError');
}

export async function sendResponse(state: IAppState): Promise<AppResponse> {
  const results: {
    sentenceId: string;
    dimensionId: string;
    answer: AnswerValues | undefined;
  }[] = state.sentences.flatMap(({ id: sentenceId }, i) =>
    state.dimensions.map(({ id: dimensionId }, j) => ({
      sentenceId,
      dimensionId,
      answer:
        state.matrice[i][j] === -1
          ? undefined
          : state.dimensionsConfig[state.matrice[i][j]].output,
    })),
  );

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const activeDuration = state.duration;
  const totalDuration = (Date.now() - state.startDate) / 1000;
  const resp = await fetch(
    `${BASE_URL + SEND_SLOT_ENDPOINT.replace('{slotId}', state.slotId)}?key=${
      state.key
    }&saveResults=${state.sending === SendStatus.CAN_SEND ? 'true' : 'false'}`,
    {
      method: 'POST',
      body: JSON.stringify({
        duration: activeDuration,
        activeDuration,
        totalDuration,
        results,
      }),
      headers,
    },
  );

  if (!resp.ok) {
    if (resp.status === 401 || resp.status === 403) {
      return err('sendResponse.unauthorized');
    }
    return err('unknownError');
  }

  const { resultsSaved, consistency } = await resp.json();

  return {
    isError: false,
    state: {
      ...state,
      sending: !resultsSaved ? SendStatus.CAN_SEND : SendStatus.SENT,
      coef: consistency.score,
      coefMessage: consistency.message,
    },
  };
}

export async function checkSlot(appState: IAppState): Promise<null | boolean> {
  const resp = await fetch(
    `${BASE_URL + CHECK_SLOT_ENDPOINT}?key=${appState.key}`,
  );

  if (!resp.ok) {
    return null;
  }

  const output = await resp.json();

  return output.hasInProgressSlot && output.slotId === appState.slotId;
}

//
// INTERACTIONS
//

function touch(appState: IAppState) {
  return {
    ...appState,
    lastChange: Date.now(),
  };
}

export async function updateInstructionsSeen(
  state: IAppState,
  seenInstructions: boolean,
): Promise<IAppState> {
  return touch({
    ...state,
    seenInstructions,
  });
}

export async function updateShowSendModal(
  state: IAppState,
  show: boolean,
): Promise<IAppState> {
  return touch({
    ...state,
    showQuestion: false,
    sending: show ? SendStatus.WANTS_TO_SEND : SendStatus.NOT_SENT,
  });
}

export async function reset(key?: string): Promise<IAppState> {
  if (!key) {
    return null as unknown as IAppState;
  }
  const resp = await getChunk(key);
  if (resp.isError) {
    return null as unknown as IAppState;
  }
  return resp.state;
}

export async function resetSlot(state: IAppState): Promise<void> {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  await fetch(`${BASE_URL + RESET_SLOT_ENDPOINT}`, {
    method: 'PUT',
    body: JSON.stringify({
      slotId: state.slotId,
    }),
    headers,
  });
}

export async function moveSentence(
  appState: IAppState,
  count: number,
): Promise<IAppState> {
  const newSentence = appState.currentSentence + count;
  if (newSentence >= 0 && newSentence <= appState.sentences.length - 1) {
    return touch({
      ...appState,
      currentSentence: newSentence,
      reachedEnd:
        newSentence === appState.sentences.length - 1
          ? true
          : appState.reachedEnd,
    });
  }

  return appState;
}

export async function moveDimension(
  appState: IAppState,
  count: number,
  relative = true,
): Promise<IAppState> {
  const newDimension = relative ? appState.currentDimension + count : count;
  if (
    newDimension >= appState.dimensions.length &&
    appState.currentSentence < appState.sentences.length - 1
  ) {
    return moveSentence(
      {
        ...appState,
        currentDimension: 0,
      },
      1,
    );
  }
  if (newDimension < 0) {
    return moveSentence(
      {
        ...appState,
        currentDimension: appState.dimensions.length - 1,
      },
      -1,
    );
  }

  if (newDimension < appState.dimensions.length) {
    return touch({
      ...appState,
      currentDimension: newDimension,
    });
  }
  return appState;
}

export async function changeAnswer(
  appState: IAppState,
  value: AnswerValues,
): Promise<IAppState> {
  const matrice = cloneDeep(appState.matrice);

  matrice[appState.currentSentence][appState.currentDimension] = value;
  return touch({ ...appState, matrice });
}

export const isLastDimension = (state: IAppState): boolean =>
  state.currentDimension === state.dimensions.length - 1;

export async function toggleQuestionDisplay(
  state: IAppState,
): Promise<IAppState> {
  return touch({
    ...state,
    showQuestion: !state.showQuestion,
  });
}
