import { Flex } from '@chakra-ui/react';
import React from 'react';
import DimensionsSelector from '../components/DimensionsSelector';
import Header from '../components/Header';
import SentencesDisplay from '../components/SentencesDisplay';
import ShortcutsFooter from '../components/ShortcutsFooter';
import {
  IAppState,
  SendStatus,
  updateInstructionsSeen,
} from '../data/appState';
import useActivityTimer from '../hooks/useActivityTimer';
import InstructionsModal from '../modals/InstructionsModal';
import SendModal from '../modals/SendModal';

const ProcessingScreen: React.FC<{
  update: (newState: IAppState | null) => void;
  appState: IAppState;
}> = ({ appState, update }) => {
  const onInstructionsValidate = async (seen: boolean) => {
    update(await updateInstructionsSeen(appState, seen));
  };

  useActivityTimer(appState, update);

  return (
    <Flex height="100vh" direction="column" justifyContent="space-between">
      <InstructionsModal
        validate={() => onInstructionsValidate(true)}
        isOpen={!appState.seenInstructions}
      />
      <SendModal
        isOpen={appState.sending !== SendStatus.NOT_SENT}
        appState={appState}
        update={update}
      />
      <Header appState={appState} update={update} />
      <SentencesDisplay appState={appState} update={update} />
      <DimensionsSelector appState={appState} update={update} />

      <ShortcutsFooter appState={appState} update={update} />
    </Flex>
  );
};

export default ProcessingScreen;
