import { extendTheme } from '@chakra-ui/react';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        color: '#000A5F',
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Radio: {
      parts: ['label'],
      baseStyle: {
        label: {
          color: 'accent.500',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        zIndex: 10000000,
      },
    },
    Divider: {
      variants: {
        primary: {
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: 'primary.500',
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        backgroundColor: '#FBFBF8',
        color: '#000A5F',
      },
      h2: {
        color: '#000A5F',
        fontWeight: 'bold',
        fontSize: 18,
        paddingBottom: 1,
        paddingTop: 3,
      },
      ul: {
        marginLeft: 4,
      },
      p: {
        paddingBottom: 2,
      },
    },
  },
  colors: {
    black: '#0A0908',
    primary: {
      300: '#7E83B5',
      500: '#000A5F',
    },
    accent: {
      500: '#FFAA17',
    },
    title: '#000A5F',
    boxes: {
      emphasisBg: '#DDD',
    },
    brand: {
      blockBg: '#eee',
    },
  },
});

export default theme;
