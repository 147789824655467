import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Heading,
  Input,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getChunk, IAppState } from '../data/appState';

const LoginScreen: React.FC<{
  update: (newState: IAppState | null) => void;
}> = ({ update }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const output = await getChunk(key);
    if (output.isError) {
      setErrorMessage(output.message);
    } else {
      update(output.state);
    }
    setLoading(false);
  };

  return (
    <div>
      <Center width="100vw" height="100vh">
        <Box
          height="180px"
          background="brand.blockBg"
          width="500px"
          fontSize="xl"
          boxShadow="lg"
          rounded="md"
        >
          <Heading height="30px" pt={2} color="title">
            {t('APP_NAME')}
          </Heading>

          <Center height="150px" width="500px">
            {loading && (
              <CircularProgress isIndeterminate color="primary.500" />
            )}
            {!loading && (
              <>
                <Flex justifyContent="center" alignItems="center" pl={3} pr={3}>
                  <Input
                    size="lg"
                    background="white"
                    placeholder={t('key')}
                    value={key}
                    onInput={(e) =>
                      setKey((e.target as HTMLInputElement).value)
                    }
                    border="2px solid"
                    borderColor="primary.500"
                  />
                  <Button
                    ml={3}
                    onClick={onSubmit}
                    p={6}
                    pt={6}
                    pb={6}
                    disabled={key.length === 0}
                    colorScheme="accent"
                  >
                    {t('start')}
                  </Button>
                </Flex>
              </>
            )}
          </Center>
          {errorMessage && errorMessage?.length > 0 && (
            <Box
              height="50px"
              pt={3}
              dangerouslySetInnerHTML={{ __html: t(errorMessage ?? '') }}
            />
          )}
        </Box>
      </Center>
    </div>
  );
};

export default LoginScreen;
