import { Box, Flex } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { COLOR_VALUES } from '../config';
import {
  AnswerEnum,
  AnswerValues,
  changeAnswer,
  IAppState,
  isLastDimension,
  moveDimension,
  moveSentence,
  toggleQuestionDisplay,
} from '../data/appState';

const KeyWithDescription: React.FC<{
  appState: IAppState;
  descLabel: string;
  children: ReactElement[] | ReactElement;
  isDisabled?: boolean;
  onPress: () => void;
}> = ({ appState, descLabel, children, onPress, isDisabled }) => {
  const { t } = useTranslation();

  const tradsOfShortcut: AnswerValues | string = t(`keys.desc.${descLabel}`);

  return (
    <Flex flexDirection="column">
      <KeyboardEventHandler
        handleKeys={[descLabel]}
        onKeyEvent={(_, e) => {
          e.preventDefault();
          onPress();
        }}
        isDisabled={isDisabled ?? false}
      />
      <Flex alignItems="center">{children}</Flex>
      <Box
        fontSize={10}
        maxWidth="110px"
        pt={1}
        textAlign="left"
        height={5}
        color="primary.300"
        opacity={isDisabled ? 0.5 : 1}
      >
        {typeof tradsOfShortcut === 'number'
          ? appState.dimensionsConfig[tradsOfShortcut].longLabel
          : t(`keys.desc.${descLabel}`)}
      </Box>
    </Flex>
  );
};

const Key: React.FC<{ children: string; bg?: string }> = ({ children, bg }) => {
  const { t } = useTranslation();
  return (
    <Flex
      height={35}
      minWidth={35}
      boxSizing="border-box"
      pl={1}
      pr={1}
      background={bg ?? 'white'}
      borderRadius={5}
      width={children === 'space' ? '150px' : 'auto'}
      whiteSpace="nowrap"
      alignItems="center"
      justifyContent="center"
      color={bg ? 'white' : 'primary.300'}
      fontWeight={600}
      fontSize={12}
    >
      {t(`keys.name.${children}`)}
    </Flex>
  );
};

const Sep: React.FC = () => (
  <Box
    minWidth="1px"
    maxWidth="1px"
    height={55}
    borderRight="1px solid #000A5F"
    ml={2}
    mr={2}
  />
);

const ShortcutsFooter: React.FC<{
  appState: IAppState;
  update: (appState: IAppState) => void;
}> = ({ appState, update }) => {
  const question = appState.sentences[appState.currentSentence].question;
  const context = appState.sentences[appState.currentSentence].context;
  const toggleQuestionAndContext = async () => {
    update(await toggleQuestionDisplay(appState));
  };

  const onChange = async (v: AnswerValues) => {
    const newState = await changeAnswer(appState, v);
    update(newState);
    setTimeout(
      async () => {
        update(await moveDimension(newState, 1));
      },
      isLastDimension(newState) ? 250 : 0,
    );
  };

  return (
    <Box background="#EFEFEF" height={100}>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        gap={5}
        mb={5}
        pt={3}
        pl={3}
      >
        <KeyWithDescription
          appState={appState}
          descLabel="tab"
          onPress={() => moveDimension(appState, 1).then(update)}
        >
          <Key>tab</Key>
        </KeyWithDescription>
        <KeyWithDescription
          appState={appState}
          descLabel="shift+tab"
          isDisabled={false}
          onPress={() => moveDimension(appState, -1).then(update)}
        >
          <Key>shift</Key>
          <Box ml={1} mr={1} color="primary.500" fontSize={11}>
            +
          </Box>
          <Key>tab</Key>
        </KeyWithDescription>
        <Sep />

        <KeyWithDescription
          appState={appState}
          descLabel="y"
          isDisabled={question || context ? false : true}
          onPress={() => {
            toggleQuestionAndContext();
          }}
        >
          {question || context ? <Key>y</Key> : <Key bg="#cccccc60">y</Key>}
        </KeyWithDescription>

        <KeyWithDescription
          appState={appState}
          descLabel="space"
          onPress={() => onChange(AnswerEnum.NOT_PRESENT)}
        >
          <Key bg={COLOR_VALUES[0]}>space</Key>
        </KeyWithDescription>
        <KeyWithDescription
          appState={appState}
          descLabel="i"
          onPress={() => onChange(AnswerEnum.RIGHT_DIMENSION)}
        >
          <Key bg={COLOR_VALUES[1]}>i</Key>
        </KeyWithDescription>
        <KeyWithDescription
          appState={appState}
          descLabel="o"
          onPress={() => onChange(AnswerEnum.NEUTRAL_DIMENSION)}
        >
          <Key bg={COLOR_VALUES[2]}>o</Key>
        </KeyWithDescription>
        <KeyWithDescription
          appState={appState}
          descLabel="p"
          onPress={() => onChange(AnswerEnum.WRONG_DIMENSION)}
        >
          <Key bg={COLOR_VALUES[3]}>p</Key>
        </KeyWithDescription>
        <Sep />
        <KeyWithDescription
          appState={appState}
          descLabel="d"
          onPress={() => {
            moveSentence(appState, -1).then(update);
          }}
        >
          <Key>d</Key>
        </KeyWithDescription>
        <KeyWithDescription
          appState={appState}
          descLabel="c"
          onPress={() => {
            moveSentence(appState, 1).then(update);
          }}
        >
          <Key>c</Key>
        </KeyWithDescription>
      </Flex>
    </Box>
  );
};

export default ShortcutsFooter;
