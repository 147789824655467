export const scrollbarCSS = {
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#7E83B5',
    borderRadius: '10px',
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
  },
};
