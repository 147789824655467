import { ChakraProvider, Box, Grid } from '@chakra-ui/react';
import * as React from 'react';
import { checkSlot, IAppState, reset } from './data/appState';
import useLocalStorage from './hooks/useLocalStorage';
import InvalidChunk from './modals/InvalidChunk';
import NoNetwork from './modals/NoNetwork';
import TimeExpired from './modals/TimeExpired';
import LoginScreen from './screens/LoginScreen';
import ProcessingScreen from './screens/ProcessingScreen';
import ErrorBoundary from './ErrorBoundary';
import theme from './style/theme';

const App = () => {
  const [appState, setAppState] = useLocalStorage<IAppState | null>(
    'app',
    null,
  );

  const [startedAsAnon] = React.useState(appState === null);
  const [validityCheckDone, setValidityCheckDone] = React.useState(false);
  const [noNetwork, setNoNetwork] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [isExpired, setIsExpired] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (!startedAsAnon && appState && !validityCheckDone) {
        setValidityCheckDone(true);
        const isValid = await checkSlot(appState);
        if (isValid === null) {
          setNoNetwork(true);
          return;
        }
        if (!isValid) {
          // eslint-disable-next-line no-console
          console.error('Unsynced localStorage, resetting app state');
          setInvalid(true);
          // setAppState(await reset());
        } else {
          setInvalid(false);
          // eslint-disable-next-line no-console
          console.info('Local storage is synced');
        }
      }
    })();
  }, [appState, setAppState, startedAsAnon, validityCheckDone]);

  React.useEffect(() => {
    if (appState?.expirationDate) {
      const tick = () => {
        const safeCurrentDate = new Date();
        safeCurrentDate.setMinutes(safeCurrentDate.getMinutes() + 1);
        if (
          appState?.expirationDate &&
          safeCurrentDate > new Date(appState.expirationDate)
        ) {
          setIsExpired(true);
        }
      };
      const interv = setInterval(tick, 60000);
      return () => {
        clearInterval(interv);
      };
    }
    return () => {};
  }, [appState?.expirationDate]);

  return (
    <ErrorBoundary onFatalError={() => {
      setAppState(null)
    }}>
      <ChakraProvider theme={theme}>
        <Box textAlign="center">
          <Grid minH="100vh">
            <InvalidChunk
              isOpen={invalid}
              validate={async () => {
                setAppState(await reset());
                setInvalid(false);
              }}
            />
            <TimeExpired
              isOpen={isExpired}
              validate={async () => {
                setAppState(await reset());
                setIsExpired(false);
              }}
            />
            <NoNetwork
              isOpen={noNetwork}
              validate={() => {
                setNoNetwork(false);
                setValidityCheckDone(false);
              }}
            />
            {appState === null ? (
              <LoginScreen update={setAppState} />
            ) : (
              <ProcessingScreen update={setAppState} appState={appState} />
            )}
          </Grid>
        </Box>
      </ChakraProvider>
    </ErrorBoundary>
  );
};

export default App;
