import { Box, VStack } from '@chakra-ui/react';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnswerEnum,
  AnswerValues,
  changeAnswer,
  IAppState,
  isLastDimension,
  moveDimension,
  SendStatus,
} from '../data/appState';
import ColorRadio from './ColorRadio';

const AnswerEnumPositive = Object.assign({}, AnswerEnum);
delete AnswerEnumPositive['NOTHING'];

const AnswerDropdown: React.FC<{
  appState: IAppState;
  update: (appState: IAppState) => void;
  dimensionsEl: RefObject<HTMLDivElement>;
  screenWidth: number;
  screenHeight: number;
}> = ({ appState, update, dimensionsEl, screenWidth, screenHeight }) => {
  const { t } = useTranslation();
  const [[bottom, left, right], setBLR] = useState<
    (string | number | undefined)[]
  >([0, 0, 0]);
  const onChange = useCallback(
    async (v: AnswerValues) => {
      const newState = await changeAnswer(appState, v);
      update(newState);
      setTimeout(
        async () => {
          update(await moveDimension(newState, 1));
        },
        isLastDimension(newState) ? 250 : 0,
      );
    },
    [appState, update],
  );

  const dimensionEl: HTMLDivElement | null = useMemo(() => {
    if (dimensionsEl.current) {
      return dimensionsEl.current.querySelector(
        `#dimensions-selector-${appState.currentDimension}`,
      );
    }
    return null;
  }, [appState.currentDimension, dimensionsEl]);

  const val: AnswerValues = useMemo(
    () => appState.matrice[appState.currentSentence][appState.currentDimension],
    [appState.currentDimension, appState.currentSentence, appState.matrice],
  );

  useEffect(() => {
    if (!dimensionEl) {
      return;
    }
    const position = dimensionEl.getBoundingClientRect();
    const localBottom = `${screenHeight - position.top + 10}px`;
    if (dimensionEl.dataset.isRight === 'true') {
      setBLR([localBottom, undefined, `${screenWidth - position.right}px`]);
    } else {
      setBLR([localBottom, `${position.left}px`, undefined]);
    }
  }, [dimensionEl, screenHeight, screenWidth]);

  if (
    dimensionEl === null ||
    !appState.seenInstructions ||
    appState.sending !== SendStatus.NOT_SENT
  ) {
    return null;
  }

  return (
    <Box
      bg="white"
      position="absolute"
      p={3}
      pt={4}
      pb={4}
      bottom={bottom}
      left={left}
      right={right}
      transition="left 100ms ease-in-out, right 100ms ease-in-out"
      boxShadow="0 3px 6px rgba(0,0,0,0.16)"
      borderRadius={10}
    >
      <VStack alignItems="start">
        {Object.values(AnswerEnumPositive).map((value) => (
          <Box key={value as number} textAlign="left">
            <ColorRadio
              checked={value === val}
              onChange={onChange}
              value={value}
            >
              {`${appState.dimensionsConfig[value].longLabel} ${t(
                `values.shortcuts.${value}`,
              )}`}
            </ColorRadio>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default AnswerDropdown;
