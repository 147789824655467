import React from 'react';
import { withTranslation } from 'react-i18next';

interface Props {
  t: (key: string) => string; // function of react-i18next
  onFatalError: () => void;
  children: React.ReactElement;
}

class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/*error*/) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(/*error, errorInfo*/) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.props.onFatalError();
    setTimeout(() => {
      this.props.onFatalError();
    }, 10000);
  }

  render() {
    if (this.state.hasError) {
      return <>
        <h1>{this.props.t('fatalError.title')}</h1>
        <h3>{this.props.t('fatalError.text')}</h3>
      </>;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
