import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InstructionsModal: React.FC<{
  isOpen: boolean;
  validate: () => void;
}> = ({ isOpen, validate }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={validate}>
      <ModalOverlay />
      <ModalContent maxWidth="90vw" width="1000px" overflow="auto">
        <ModalHeader>{t('instructions.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            dangerouslySetInnerHTML={{ __html: t('instructions.messageHTML') }}
            sx={{
              fontSize: 14,
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button mr={3} colorScheme="accent" onClick={validate}>
            {t('instructions.ok')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InstructionsModal;
