import { Box, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { COLOR_VALUES } from '../config';

const ColorRadio: React.FC<{
  value: number;
  checked: boolean;
  onChange: (v: number) => void;
  children: string;
}> = ({ value, onChange, checked, children }) => (
  <HStack as="label" cursor="pointer" onClick={() => onChange(value)}>
    <Flex
      width={4}
      height={4}
      borderRadius="50%"
      background={COLOR_VALUES[value]}
      justifyContent="center"
      alignItems="center"
    >
      {!checked && (
        <Box width={2} height={2} borderRadius="50%" background="white" />
      )}
    </Flex>
    <Box fontSize={12} color="primary.500" fontWeight={600}>
      {children}
    </Box>
  </HStack>
);

export default ColorRadio;
