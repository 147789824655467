import { useEffect, useState } from 'react';
import { ACTIVITY_TIMER } from '../config';
import { IAppState } from '../data/appState';

const useActivityTimer = (
  appState: IAppState,
  update: (newState: IAppState | null) => void,
) => {
  const [lastCheck, setLastCheck] = useState(+Date.now());
  const [nextCheck, setNextCheck] = useState(+Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNextCheck(+Date.now());
    }, ACTIVITY_TIMER);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (lastCheck !== nextCheck) {
      if (nextCheck - appState.lastChange < ACTIVITY_TIMER) {
        update({
          ...appState,
          duration: appState.duration + ACTIVITY_TIMER / 1000,
        });
      }

      setLastCheck(nextCheck);
    }
  }, [appState, lastCheck, nextCheck, update]);

  return null;
};

export default useActivityTimer;
