import { Box, Center, Grid, GridItem, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_VALUES, UI_MIN_DIM_PX_WIDTH } from '../config';
import { IAppState, moveDimension } from '../data/appState';
import AnswerDropdown from './AnswerDropdown';

const DimensionBlock: React.FC<{
  appState: IAppState;
  idx: number;
  onClick: () => void;
  isRight: boolean;
  isLast: boolean;
  isFirst: boolean;
}> = ({ appState, idx, onClick, isRight, isLast, isFirst }) => {
  const { t } = useTranslation();

  const value = appState.matrice[appState.currentSentence][idx];

  const bgColor = COLOR_VALUES[value];

  (document.activeElement as HTMLElement).blur();

  return (
    <GridItem
      cursor={appState.currentDimension === idx ? undefined : 'pointer'}
      onClick={appState.currentDimension === idx ? undefined : () => onClick()}
      id={`dimensions-selector-${idx}`}
      data-is-right={isRight ? 'true' : 'false'}
      data-is-last={isLast ? 'true' : 'false'}
      data-is-first={isFirst ? 'true' : 'false'}
    >
      <Box
        bg={bgColor}
        transition="background-color 250ms ease-out, border-width 150ms ease-out"
        color="white"
        fontWeight="bold"
        fontSize={14}
        height={10}
        boxSizing="border-box"
        border={`${appState.currentDimension === idx ? 3 : 0}px solid #000A5F`}
        borderRadius={6}
      >
        <Center height={10}>
          {appState.dimensionsConfig[value].shortLabel ??
            t(`values.shortLabel.${value}`)}
        </Center>
      </Box>
    </GridItem>
  );
};

const DimensionsSelector: React.FC<{
  appState: IAppState;
  update: (appState: IAppState) => void;
}> = ({ appState, update }) => {
  const [[screenWidth, screenHeight], setScreenDimensions] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const changeDimension = async (idx: number) => {
    update(await moveDimension(appState, idx, false));
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = () => {
      setScreenDimensions([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const [cutAt, groups] = useMemo(() => {
    let localGroups: (IAppState['dimensions'][0] | null)[][] = [
      appState.dimensions,
    ];
    let localCutAt = 0;
    if (screenWidth < appState.dimensions.length * UI_MIN_DIM_PX_WIDTH) {
      localCutAt = Math.ceil(appState.dimensions.length / 2);
      localGroups = [
        appState.dimensions.slice(0, localCutAt),
        appState.dimensions.slice(localCutAt),
      ];
      if (localGroups[1].length < localGroups[0].length) {
        localGroups[1].push(null);
      }
    }
    return [localCutAt, localGroups];
  }, [appState.dimensions, screenWidth]);

  return (
    <>
      <Grid
        templateColumns={`repeat(${groups[0].length}, 1fr)`}
        rowGap={1}
        columnGap={2}
        ref={containerRef}
        p={5}
        mt={100}
      >
        {groups.flatMap((dims, gid) => [
          ...dims.map((_, i) => {
            const idx = gid * cutAt + i;
            if (idx >= appState.dimensions.length) {
              return <div />;
            }
            return (
              <DimensionBlock
                idx={idx}
                key={`${idx}-value`}
                appState={appState}
                onClick={() => changeDimension(idx)}
                isRight={i > dims.length / 2}
                isLast={i === dims.length - 1}
                isFirst={i === 0}
              />
            );
          }),
          ...dims.map((d) => {
            if (d === null) {
              return <div />;
            }
            const { id, label, info } = d;
            return (
              <Tooltip
                label={info}
                key={`tooltip-${id}`}
                placement="bottom-start"
              >
                <GridItem
                  key={`${id}-label`}
                  width="100%"
                  fontSize={11}
                  textAlign="left"
                  pl={1}
                  pr={1}
                  fontWeight={600}
                  color="primary.500"
                >
                  {label}
                </GridItem>
              </Tooltip>
            );
          }),
        ])}
      </Grid>
      {containerRef.current && (
        <AnswerDropdown
          appState={appState}
          update={update}
          dimensionsEl={containerRef}
          screenWidth={screenWidth}
          screenHeight={screenHeight}
        />
      )}
    </>
  );
};

export default DimensionsSelector;
