import {
  Box,
  Button,
  Center,
  CircularProgress,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAppState,
  reset,
  sendResponse,
  SendStatus,
  updateShowSendModal,
} from '../data/appState';

const SendModal: React.FC<{
  isOpen: boolean;
  update: (appState: IAppState) => void;
  appState: IAppState;
}> = ({ isOpen, appState, update }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = async () => {
    if (loading) {
      return;
    }
    update(await updateShowSendModal(appState, false));
  };

  const send = useCallback(async () => {
    setErrorMessage('');
    setLoading(true);
    const output = await sendResponse(appState);
    setLoading(false);
    if (output.isError) {
      setErrorMessage(output.message);
      return;
    }
    update(output.state);
  }, [appState, update]);

  const finish = useCallback(
    async (newChunk: boolean) => {
      setErrorMessage('');
      setLoading(true);
      update(await reset(newChunk ? appState.key : undefined));
      setLoading(false);
    },
    [appState.key, update],
  );

  useEffect(() => {
    if (isOpen && appState.sending === SendStatus.WANTS_TO_SEND) {
      send();
    }
  }, [isOpen, appState, send]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={appState.sending !== SendStatus.SENT ? closeModal : () => {}}
    >
      <ModalOverlay />
      <ModalContent height={350}>
        <ModalHeader>{t('sendModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errorMessage.length > 0 && (
            <Box
              bg="tomato"
              width="100%"
              color="white"
              padding={2}
              dangerouslySetInnerHTML={{ __html: t(errorMessage) }}
            />
          )}
          {loading && (
            <Center width="100%" height={150}>
              <CircularProgress isIndeterminate color="primary.500" />
            </Center>
          )}
          {!loading && (
            <>
              {[SendStatus.CAN_SEND, SendStatus.SENT].includes(
                appState.sending,
              ) && (
                <Box fontWeight="bold">
                  {t(`sendModal.message.${appState.sending}`, {
                    coef: Math.round(appState.coef ?? NaN),
                  })}
                </Box>
              )}
              {appState.sending === SendStatus.CAN_SEND &&
                appState.coefMessage && (
                  <Box
                    mt={2}
                    dangerouslySetInnerHTML={{ __html: appState.coefMessage }}
                    fontSize={14}
                  />
                )}
            </>
          )}
        </ModalBody>

        {!loading && (
          <ModalFooter>
            {appState.sending !== SendStatus.SENT ? (
              <>
                <Button variant="outline" mr={3} onClick={closeModal}>
                  {t('sendModal.button.quit')}
                </Button>
                <Button mr={3} onClick={send} colorScheme="accent">
                  {t(`sendModal.button.send`)}
                </Button>
              </>
            ) : (
              <>
                <Button variant="outline" mr={3} onClick={() => finish(false)}>
                  {t('sendModal.button.home')}
                </Button>
                <Button mr={3} onClick={() => finish(true)}>
                  {t(`sendModal.button.new`)}
                </Button>
              </>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SendModal;
