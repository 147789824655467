import {
  Box,
  Center,
  Divider,
  VStack,
  Fade,
  CloseButton,
} from '@chakra-ui/react';
import React from 'react';
import { IAppState } from '../data/appState';

const WrapperQuestionToast: React.FC<{
  appState: IAppState;
  close: () => void;
}> = ({ appState, close }) => {
  const currentSentence = appState.sentences[appState.currentSentence];

  if (!currentSentence.question && !currentSentence.context) return <></>;

  return (
    <QuestionToast
      questionTitle={appState.toastTitles.questionTitle}
      contextTitle={appState.toastTitles.contextTitle}
      close={close}
      question={currentSentence.question}
      context={currentSentence.context}
      showQuestion={appState.showQuestion}
    />
  );
};

const QuestionToast: React.FC<{
  questionTitle: string;
  contextTitle: string;
  question?: string;
  context?: string;
  showQuestion: boolean;
  close: () => void;
}> = ({
  questionTitle,
  contextTitle,
  question,
  context,
  showQuestion,
  close,
}) => {
  return (
    <Box position="absolute" zIndex={1}>
      <Fade in={showQuestion}>
        <VStack
          spacing={1}
          align="center"
          position="fixed"
          left="50%"
          transform="translate(-50%, 0)"
          backgroundColor="#FDFDFD"
          p="1rem"
          mt="5px"
          borderRadius={'6'}
          boxShadow={'lg'}
        >
          <CloseButton
            position={'absolute'}
            size={'sm'}
            onClick={close}
            right={2}
            top={2}
          />
          {question && (
            <Box>
              <Center>
                <Box color="primary.500" fontWeight="600" fontSize={22}>
                  {questionTitle}
                </Box>
              </Center>
              <Box>
                <Center color="primary.500" fontWeight="200" fontSize={22}>
                  {question}
                </Center>
              </Box>
            </Box>
          )}
          {question && context && <Divider variant="primary" mt={2} mb={1} />}
          {context && (
            <Box>
              <Center>
                <Box color="primary.500" fontWeight="600" fontSize={22}>
                  {contextTitle}
                </Box>
              </Center>
              <Box>
                <Center color="primary.500" fontWeight="200" fontSize={22}>
                  {context}
                </Center>
              </Box>
            </Box>
          )}
        </VStack>
      </Fade>
    </Box>
  );
};

export default {
  Wrapper: WrapperQuestionToast,
  Component: QuestionToast,
};
